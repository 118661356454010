var matomoConfig;

if (process.env.NODE_ENV === 'pro') {
	matomoConfig = {
		NODE_ENV: 'pro',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: 10 // Dado por tech para PRO
	};
} else if (process.env.NODE_ENV === 'pre') {
	matomoConfig = {
		NODE_ENV: 'pre',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: 10 // Dado por tech para PRE
	};
} else {
	matomoConfig = {
		NODE_ENV: 'dev',
		MATOMO_URL: 'https://analytics.prodevelop-cdn.com/',
		MATOMO_ID: 11 // Dado por tech para DEV
	};
}

export default {
	// base URL of our API
	//baseURL_DAC_API: 'http://172.17.0.1:3000',
	baseURL_DAC_API: '/dac-api', //Deployment on AWS
	//baseURL_DAC_API: 'http://localhost:3000', //local execution

	//baseURL_FILE_API: 'http://localhost:3050', // Local deployment
	baseURL_FILE_API: '/file-management', //Deployment on AWS

	//baseURL_DATABASES_API: 'http://localhost:3030', // Local deployment
	baseURL_DATABASES_API: '/databases-api', //Deployment on AWS

	//baseURL_AUTOMATION_TOOL: '/automation-tool',
	//baseURL_AUTOMATION_TOOL: 'https://multireload.prodevelopataws.com',
	baseURL_AUTOMATION_TOOL: 'http://localhost',

	// Orchestration api for Emissions Calc Machine
	LOCAL_ORCH: 'http://localhost:20000/',

	// methods petition API
	METHOD_GET: 'GET',
	METHOD_POST: 'POST',
	METHOD_PUT: 'PUT',
	METHOD_DELETE: 'DELETE',
	METHOD_PATCH: 'PATCH',
	SUPER_ADMIN_ROLE: 'SUPER_ADMIN_SP',
	ADMIN_ROLE: 'ADMIN_SP',
	VIEWER_ROLE: 'VIEWER_SP',
	//POLLUTANTS: ['NO2', 'SO2', 'O3', 'PM10', 'PM2.5'],
	POLLUTANTS: [
		{ label: 'NO2 (Daily & forecast)', value: 'NO2' },
		{ label: 'SO2 (Daily & forecast)', value: 'SO2' },
		{ label: 'O3 (Daily & forecast)', value: 'O3' },
		{ label: 'PM10 (Forecast)', value: 'PM10' },
		{ label: 'PM2.5 (Forecast)', value: 'PM2.5' },
		{ label: 'CO (Daily & forecast)', value: 'CO' },
		{ label: 'NO (Forecast)', value: 'NO' },
		{ label: 'DUST (Forecast)', value: 'DUST' },
		{ label: 'Formaldehyde (Daily)', value: 'HCHO' },
		{ label: 'Absorbing aerosol index (Daily)', value: 'AER_AI' }
	],
	WATER_POLLUTANTS: ['CHL', 'SPM', 'TUR', 'SST'],
	WINDOW_SIZE_FORECAST: [
		{ label: '24 hours', value: '24' },
		{ label: '48 hours', value: '48' },
		{ label: '72 hours', value: '72' }
	],
	WINDOW_TIME_UNIT: ['SECONDS', 'MINUTES', 'HOURS', 'DAYS'],
	CAMS_ACRONYM: 'CAMS',
	CMEMS_ACRONYM: 'CMEMS',
	S5P_ACRONYM: 'S5P',
	NOTIFICATIONS_ID: 'pmnotificationsid',
	FEATURE_TYPE_NOTIFICATION: 'notification',
	FEATURE_TYPE_DEVICE: 'device',
	RESPONSE_OK: 'OK',
	VISUALIZATION_TYPE_HISTORIC: 'HISTORIC',
	VISUALIZATION_TYPE_REAL_TIME: 'REAL_TIME',
	VISUALIZATION_TYPE_PREDICTIONS: 'PREDICTIONS',
	VISUALIZATION_TYPE_NOTIFICATIONS: 'NOTIFICATIONS',
	VISUALIZATION_TYPE_COMPAREAQI: 'COMPAREAQI',

	// Icons Types
	ORG_ICONS_TYPE_ACRONYM: 'ORG',
	DTS_ICONS_TYPE_ACRONYM: 'DTS',
	APP_ICONS_TYPE_ACRONYM: 'APP',
	DEFAULT_APP_ICONS_TYPE: 'DEF_A',
	DEFAULT_ORG_ICONS_TYPE: 'DEF_O',
	DEFAULT_DTS_ICONS_TYPE: 'DEF_D',
	NOTIFICATIONS_ICONS_TYPE: 'NOTIF',
	ACTIONS_ICONS_TYPE: 'ACTIONS',
	// Notifications Tab Icons
	NOTIFICATIONS_TAB_ACRONYM: 'TAB',
	NOTIFICATIONS_SELECTED_ACRONYM: 'TAB_S',
	// Layers models constants
	DEVICES_MODEL: 'pmdatasourceinstance',
	NOTIFICATIONS_MODEL: 'pmnotifications',
	SATELLITE_MODEL: 'pmsatelliteimages',
	// Layer area Notification
	AREA_NOTIFICATION: 'areanotification',
	// Constants date format
	DATE_FORMAT_GMT: 'GMT_FORMAT',
	DATE_FORMAT_GENERIC_FORMAT: 'GENERIC_FORMAT',
	DATE_FORMAT_SPANISH_FORMAT: 'SPANISH_FORMAT',
	DATE_FORMAT_EUROPEAN_FORMAT: 'EUROPEAN_FORMAT',
	DATE_FORMAT_AMERICAN_FORMAT: 'AMERICAN_FORMAT',
	DATE_FORMAT_ISO_8601: 'ISO_8601',
	DATE_FORMAT_RFC_2822: 'RFC_2822',
	// Elastic Property Names
	ELASTIC_DATE_OBSERVED: 'dateObserved',
	ELASTIC_DATE_OBSERVED_TIMESTAMP: 'dateObserved_timestamp',
	// Datasourcetype Satellite
	DATASOURCETYPE_SATELLITE: 'satellite',
	MEASUREMENT_LINE: 'LineString',
	MEASUREMENT_POLYGON: 'Polygon',

	APPLICATION_NAME: 'Posidonia Green',
	APPLICATION_CLASS: 'fa-logo-p-green',
	//APPLICATION_NAME: 'APPLICATION NAME',
	//APPLICATION_CLASS: 'fa-solid-setting',

	COPERNICUS_ICON: 'COPERNICUS',

	ELASTIC_INDEX_PREFIX: 'sp',
	ELASTIC_NOTIFICATIONS_SUFFIX: 'notifications',
	ELASTIC_ERROR_SUFFIX: '_error',
	HISTORICAL_NOTIFICATIONS_INDEX: 'sp_historical_notifications',
	NOTIFICATION_PANEL_THRESHOLD: 10,
	SLA_DEVICES_THRESHOLD: 12,
	NO_LANG: 'NO_LANG',
	PARAMETER_NOT_PROCESSED: 'DQ_PARAMETER_NOT_PROCESSED',
	PARAMETER_BAD_DATATYPE: 'DQ_PARAMETER_BAD_DATATYPE',
	FREQUENCY_LATE: 'DQ_PARAMETER_FREQUENCY_HIGHER',
	FREQUENCY_EARLY: 'DQ_PARAMETER_FREQUENCY_LOWER',
	INTEGER: 'Integer',
	FLOAT: 'Float',
	BOOLEAN: 'Boolean',
	STRING: 'String',
	FEATURE_TABS: {
		REAL_TIME: 'real-time',
		HISTORIC: 'historic',
		ADVANCED: 'advanced',
		PREDICTIONS: 'predictions',
		AQI: 'compare-aqi',
		XAI: 'device-xai',
		ACTIVE_NOTIFICATIONS: 'active-notifications',
		HISTORIC_NOTIFICATIONS: 'historic-notifications',
		ACTIONS_LOG: 'actions-log'
	},
	VISUALIZATIONS: {
		TIME_SERIES: 'XLINE',
		HISTOGRAM: 'HISTO',
		AREA_CHART: 'AREA',
		FREQUENCY_HISTOGRAM: 'FREQ',
		FREQUENCY_PIE: 'PIE',
		STACKED_SERIES: 'STACK',
		HEATMAP: 'HMAP',
		GRAPHIC3D: '3DGRA',
		BAR_MOBILE: 'BARMO',
		TIME_MOBILE: 'TIMMO'
	},
	DASHBOARD: {
		SLA: 'SLA',
		WARNING: 'WARNING',
		FREQUENCY: 'FREQUENCY',
		ERROR: 'ERROR',
		TYPES: 'TYPES',
		GLOBAL: 'GLOBAL',
		AREA: 'AREA',
		DATASOURCE: 'DATASOURCE',
		DEVICE: 'DEVICE',
		SUMMARY: 'SUMMARY'
	},
	RANGE_TOLERANCE: 10,
	ACTION_TYPES: [
		{ text: 'pmactions.actiontypes.manual', value: 'MANUAL' },
		{ text: 'pmactions.actiontypes.automatic', value: 'AUTOMATIC' }
	],
	ACTION_METHODS: [
		{ text: 'pmactions.actionmethods.GET', value: 'GET' },
		{ text: 'pmactions.actionmethods.POST', value: 'POST' },
		{ text: 'pmactions.actionmethods.PUT', value: 'PUT' },
		{ text: 'pmactions.actionmethods.DELETE', value: 'DELETE' }
	],
	AUTH_TYPES: [
		{ text: 'pmactions.authtypes.none', value: 'none' },
		{ text: 'pmactions.authtypes.basic', value: 'basic' },
		{ text: 'pmactions.authtypes.token', value: 'token' }
	],
	// ,		{ text: 'pmactions.authtypes.oauth2', value: 'oauth2' }
	ACTION_RESULT_SUCCESS: 'SUCCESS',
	ACTION_RESULT_FAILURE: 'FAILURE',
	ACTION_RESULT_COMPLETED: 'COMPLETED',
	RASTER: {
		SETTINGS: 'rasterlistSettings',
		MAP1: 'rasterlistCompareMap1',
		MAP2: 'rasterlistCompareMap2'
	},
	...matomoConfig
};
