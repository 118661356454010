import { NOTIFICATIONS_LAYER, AQIPOINTS_LAYER } from '../lib/constants/layers';
import constants from '../utils/constants';

export default {
	getNotificationsLayerConstants: (state) => {
		return state.layers;
	},
	// MAP
	loading: (state) => {
		return state.mappanel.loading;
	},

	mapLoaded: (state) => {
		return state.mappanel.mapLoaded;
	},

	layers: (state) => {
		return state.mappanel.layers;
	},
	notificationLayers: (state) => {
		if (Object.keys(state.mappanel.layers).length > 0) {
			return Object.values(state.mappanel.layers).filter((layer) => layer.model == constants.NOTIFICATIONS_MODEL);
		}
		return false;
	},
	getNotifications: (state) => {
		if (state.data.notificationData) {
			return state.data.notificationData.sort((a, b) => {
				return b.priority - a.priority;
			});
		}
		return state.data.notificationData;
	},
	rasterLayers: (state) => {
		return state.mappanel.rasterLayersSettings;
	},
	rasterLayersData: (state) => {
		return state.mappanel.rasterLayersData;
	},
	getRasterDateSelected: (state) => {
		return state.mappanel.rasterDateSelected;
	},
	externalLayers: (state) => {
		return state.mappanel.externalLayers;
	},
	getGeoJsonLayers: (state) => {
		return state.mappanel.geoJsonLayers;
	},
	getGeoJsonLayersData: (state) => {
		return state.mappanel.geoJsonLayersData;
	},

	/* notificationsLayer: state => {
		return state.mappanel.layers[NOTIFICATIONS_LAYER];
	}, */

	notificationsLayer: (state) => (index) => {
		return state.mappanel.layers[NOTIFICATIONS_LAYER + index];
	},

	aqiPointsLayer: (state) => {
		return state.mappanel.layers[AQIPOINTS_LAYER];
	},

	zoom: (state) => {
		return state.mappanel.zoom;
	},

	bbox: (state) => {
		return state.mappanel.bbox;
	},

	refreshTime: (state) => {
		return state.mappanel.refreshTime;
	},

	// MAP FEATURE CARD
	getFeatureClick: (state) => {
		return state.mappanel.featureCard.featureClick;
	},
	getFeaturePopupClick: (state) => {
		return state.mappanel.featureCard.featurePopupClick;
	},

	getFeatureType: (state) => {
		return state.mappanel.featureCard.featureType;
	},

	getDisplayMode: (state) => {
		return state.mappanel.featureCard.displayMode;
	},

	getSelectedTabDevice: (state) => {
		return state.mappanel.featureCard.selectedTabDevice;
	},
	getSelectedTabDatasource: (state) => {
		return state.mappanel.featureCard.selectedTabDatasource;
	},
	getSelectedTabHistoric: (state) => {
		return state.mappanel.featureCard.selectedTabHistoric;
	},
	getSelectedTabAdvanced: (state) => {
		return state.mappanel.featureCard.selectedTabAdvanced;
	},
	getSelectedTabPrediction: (state) => {
		return state.mappanel.featureCard.selectedTabPrediction;
	},
	getSelectedTabNotification: (state) => {
		return state.mappanel.featureCard.selectedTabNotification;
	},
	getSelectedTabNotificationDialog: (state) => {
		return state.mappanel.featureCard.selectedTabNotificationDialog;
	},
	getHasHistoricNotifications: (state) => {
		return state.mappanel.featureCard.hasHistoricNotifications;
	},
	getHistoricStore: (state) => {
		return state.mappanel.featureCard.formHistoric;
	},
	getAdvancedStore: (state) => {
		return state.mappanel.featureCard.formAdvanced;
	},
	getAdvancedDatasourceStore: (state) => {
		return state.mappanel.featureCard.formAdvancedDatasource;
	},
	getPredictionsStore: (state) => {
		return state.mappanel.featureCard.formPredictions;
	},
	getXAIStore: (state) => {
		return state.mappanel.featureCard.formXAI;
	},
	getCompareAqiStore: (state) => {
		return state.mappanel.featureCard.formCompareAqi;
	},
	getDateInterval: (state) => (index) => {
		return state.mappanel.featureCard.formHistoric.find((form) => form.index === index).dateInterval;
	},
	getFormHistoric: (state) => (index) => {
		return state.mappanel.featureCard.formHistoric.find((form) => form.index === index);
	},
	getAqiDescriptions: (state) => {
		return state.mappanel.featureCard.aqiDescriptions;
	},
	getFullscreenDialog: (state) => {
		return state.mappanel.featureCard.fullscreenDialog;
	},
	// TOTALS KPIS
	totalNotifications_byArea: (state) => {
		return {
			global: state.data[NOTIFICATIONS_LAYER].counts_byArea.global,
			viewport: state.data[NOTIFICATIONS_LAYER].counts_byArea.viewport
		};
	},

	totalNotifications_byDatasourcetype: (state) => {
		return {
			global: state.data[NOTIFICATIONS_LAYER].counts_byDatasourcetype.global,
			viewport: state.data[NOTIFICATIONS_LAYER].counts_byDatasourcetype.viewport
		};
	},

	featuresNotifications: (state) => {
		return state.data[NOTIFICATIONS_LAYER].viewportFeatures;
	},

	/* getViewPortFeatures: (state) => {
		return state.data.notifications.viewportFeatures
	}, */

	statusHistogramNotifications_byArea: (state) => {
		return state.data[NOTIFICATIONS_LAYER].statusHistogram_byArea;
	},

	statusHistogramNotifications_byDatasourcetype: (state) => {
		return state.data[NOTIFICATIONS_LAYER].statusHistogram_byDatasourcetype;
	},

	// KPI's
	notificationsKpiSelected_byArea: (state) => {
		return state.mappanel.notifications_byArea.selectedKpi;
	},

	notificationsKpiSelected_byDatasourcetype: (state) => {
		return state.mappanel.notifications_byDatasourcetype.selectedKpi;
	},

	// OFFSETS
	notificationsOffset: (state) => {
		return state.data[NOTIFICATIONS_LAYER].offset;
	},

	notificationsLimit: (state) => {
		return state.data[NOTIFICATIONS_LAYER].limit;
	},

	// DYNAMIC PANELS
	activePanel: (state) => {
		return state.mappanel.activePanel;
	},

	activePanelOptions: (state) => {
		return state.mappanel.activePanelOptions;
	},

	dynamicPanels: (state) => {
		return state.mappanel.dynamicPanels;
	},

	getUserProperties: (state) => {
		return state.user.properties;
	},

	// Areas dinámicas para KPIs
	getAreas: (state) => {
		return state.data.areas;
	},

	// Datasourcetype dinámico para KPIs
	getDatasourcetype: (state) => {
		return state.data.datasourcetype;
	},

	// Url
	getBaseUrl: (state) => {
		return state.global.baseUrl;
	},

	// Icons
	getIcons: (state) => {
		return state.data.icons;
	},

	// Icons ORG
	getIconsOrg: (state) => {
		return state.selectData.iconsOrg;
	},
	// Icons DEV
	getIconsDev: (state) => {
		return state.selectData.iconsDev;
	},
	// Icons DTS
	getIconsDts: (state) => {
		return state.selectData.iconsDts;
	},
	// Icons APP
	getIconsApp: (state) => {
		return state.selectData.iconsApp;
	},

	// Devices
	getDevices: (state) => {
		return state.mappanel.devices;
	},
	getDevicesData: (state) => {
		return state.mappanel.devicesData;
	},
	getHasDevices: (state) => {
		return state.mappanel.hasDevices;
	},

	// Aqi Points
	getAqiPoints: (state) => {
		return state.mappanel.aqipoints;
	},
	getHasAqiPoints: (state) => {
		return state.mappanel.hasAqiPoints;
	},

	// Compare Map
	getCompareMap: (state) => {
		return state.mappanel.compareMap;
	},
	getHasCompareMap: (state) => {
		return state.mappanel.hasCompareMap;
	},

	// Layers
	getHasLayers: (state) => {
		return state.mappanel.hasLayers;
	},

	// External Layers
	getHasExternalLayers: (state) => {
		return state.mappanel.hasExternalLayers;
	},

	// Raster Layer Active
	isRasterActive: (state) => {
		return state.settingsPanel.isRasterActive;
	},
	getActiveRasterLayer: (state) => {
		return state.settingsPanel.activeRasterLayer;
	},

	// Raster Layer Active Compare Map 1
	isRasterCompareMap1Active: (state) => {
		return state.compareMapPanel.isRasterCompareMap1Active;
	},
	getActiveCompareMap1RasterLayer: (state) => {
		return state.compareMapPanel.activeCompareMap1RasterLayer;
	},

	// Raster Layer Active Compare Map 2
	isRasterCompareMap2Active: (state) => {
		return state.compareMapPanel.isRasterCompareMap2Active;
	},
	getActiveCompareMap2RasterLayer: (state) => {
		return state.compareMapPanel.activeCompareMap2RasterLayer;
	},

	// GeoJSON Layer Active
	isGeoJsonActive: (state) => {
		return state.settingsPanel.isGeoJsonActive;
	},
	getActiveGeoJsonLayer: (state) => {
		return state.settingsPanel.activeGeoJsonLayer;
	},
	// Parameters
	getParameters: (state) => {
		return state.mappanel.parameters;
	},
	getParameterStatus: (state) => {
		return state.mappanel.parameterStatus;
	},
	getSatelliteParameters: (state) => {
		return state.mappanel.satelliteParameters;
	},
	// Actions
	getActions: (state) => {
		return state.mappanel.actions;
	},
	getActionsLogs: (state) => {
		return state.mappanel.actionsLogs;
	},
	// SLA Dashboard
	getSlaDate: (state) => {
		return state.dashboard.slaDate;
	},
	getSlaDevices: (state) => {
		return state.dashboard.slaDevices;
	},
	getDashboardTab: (state) => {
		return state.dashboard.dashboardTab;
	},
	getContentTab: (state) => {
		return state.dashboard.contentTab;
	},
	getSlaChartFilter: (state) => {
		return state.dashboard.slaChartFilter;
	},

	// Aplication
	getApplication: (state) => {
		return state.data.application;
	},

	// URL Base for Icons
	getUrlBaseIcons: (state) => {
		return state.data.urlBaseIcons;
	},

	// ELASTIC
	getPopupElasticData: (state) => {
		return state.elastic.popupData;
	},
	getLoadingPopup: (state) => {
		return state.elastic.loadingPopup;
	},
	getRealTimeElasticData: (state) => {
		return state.elastic.realTimeData;
	},
	getDatasourceRealTimeData: (state) => {
		return state.elastic.datasourceRealTimeData;
	},
	getHistoricElasticData: (state) => {
		return state.elastic.historicData;
	},
	getCompareAqiElasticData: (state) => {
		return state.elastic.compareAqiData;
	},
	getAdvancedHistoricElasticData: (state) => {
		return state.elastic.advancedHistoricData;
	},
	getAdvancedDatasourceElasticData: (state) => {
		return state.elastic.advancedDatasourceData;
	},
	// ELASTIC DATE RANGES

	getHistoricLastDates: (state) => {
		return state.elastic.historicLastDates;
	},
	getHistoricFirstDates: (state) => {
		return state.elastic.historicFirstDates;
	},

	getPredictionLastDates: (state) => {
		return state.elastic.predictionLastDates;
	},
	getPredictionFirstDates: (state) => {
		return state.elastic.predictionFirstDates;
	},

	getCompareAqiLastDates: (state) => {
		return state.elastic.compareAqiLastDates;
	},
	getCompareAqiFirstDates: (state) => {
		return state.elastic.compareAqiFirstDates;
	},

	getRules: (state) => {
		return state.data.rules;
	},
	getTimeunits: (state) => {
		return state.data.timeunits;
	},
	getErrorCodes: (state) => {
		return state.data.errorCodes;
	},

	getFormFilters: (state) => {
		return state.data.form.formFilters;
	},
	getAreaDefinitions: (state) => {
		return state.data.form.areaDefinitions;
	},
	getSeverities: (state) => {
		return state.data.severities;
	},
	getAllParameters: (state) => {
		return state.data.parameters;
	},
	getFilterData: (state) => {
		return state.data.form.filterData;
	},
	// MISC
	getCollapsedInfo: (state) => {
		return state.misc.collapsedInfo;
	},
	getCenterCoordinates: (state) => {
		return state.map.centerCoordinates;
	},
	getStatusFilters: (state) => {
		return state.data.form.statusFilters;
	}
};
